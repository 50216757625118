const searchbar = document.querySelector('.menu_top_searchbar')

const opensearchbarModalIcon = document.querySelector('.menu_top_searchbar_mobile_icon')
const closeSearchbarModalIcon = document.querySelector('.close_searchbar_mobile_modal')

function toggleSearchbar() {
  searchbar.classList.toggle('active')
  document.querySelector('.ui-autocomplete-input').focus()
}

opensearchbarModalIcon && opensearchbarModalIcon.addEventListener('click', () => toggleSearchbar())
closeSearchbarModalIcon && closeSearchbarModalIcon.addEventListener('click', () => toggleSearchbar())

// Hamburger
const menuBottomContainer = document.querySelector('#menu_bottom_container')
if (menuBottomContainer) {
  const ham = document.querySelector('.ham')
  ham.addEventListener('click', () => {
    ham.classList.toggle('active')
    menuBottomContainer.classList.toggle('active')
  })
}

// Langage dropdown
const currentLangage = document.querySelector('img.current_lang')
if (currentLangage) {
  const langagesDropdown = document.querySelector('.langages_dropdown')
  console.log(langagesDropdown)
  currentLangage.addEventListener('click', () => langagesDropdown.classList.toggle('active'))
}

// // Menu position on scroll
// const menu = document.querySelector('#mdigimenu')
// // const menuMobile = document.querySelector('#menu-icon')
// const firstBurger = document.querySelector('.burger_container')

// function handleSticky() {
//     // let offset = menu.offsetHeight;
//     let offset = menu.offsetHeight;

//     if (window.scrollY > offset - 10) {
//         menu.classList.add("stickit");
//         firstBurger.style.cssText = `margin-top: ${offset}px !important`
//     } else if (window.scrollY < offset - 20) {
//         menu.classList.remove("stickit");
//         firstBurger.style.marginTop = 0
//     }
// }
// window.addEventListener("scroll", () => handleSticky());
